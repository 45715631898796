import constants from "@/shared/statics/constants"

export const usernameIsValid = (username: string) => {
  return !!username && constants.usernameRegex.test(username) && username.length <= 15
}

export const passwordIsValid = (pw: string) => {
  return !!pw && pw.length >= 8
}

export const userOrAnon = (user: any) => {
  if (!user || !user.id) return { id: constants.anonUserId, isAnonymous: true }
  return user
}
